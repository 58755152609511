import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import type { Request, Response } from 'express';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { languages } from '@/../public/static/languages/sortedList';
import { LOHPLayout } from '@/components/LoggedOutLayout/LOHPLayout';
import { HeroHeader } from '@/components/LOHP/Hero';
import { SellingPoints } from '@/components/LOHP/SellingPoints';
import { socialShutterstockLinks } from '@/components/SocialMediaShareIcons/socialMediaLinks';
import { LOHPPageSections } from '@/constants/analytics';
import { EMAIL } from '@/constants/contactInfo';
import { SCHEMA_TYPES, SCHEMA_URL, SCRIPT_TYPES } from '@/constants/seo';
import { withAuth } from '@/middleware/authentication';
import { CurrentUser } from '@/model/currentUser';
import { NAMESPACE_COMMON, NAMESPACE_LOHP } from '@/translations/namespaces';
import { handleServerSidePropsError } from '@/utils/serverErrorHandling';

type Args = {
  req: Request;
  res: Response<any, Record<string, any>>;
  locale: string;
};

const {
  publicRuntimeConfig: { contributorCarbonWeb },
} = getConfig();

const DynamicFirstStoryTellingGrid = dynamic(() =>
  import('@/components/LOHP/StoryTellingGrids').then((mod) => mod.FirstStoryTellingGrid),
);
const DynamicResources = dynamic(() => import('@/components/LOHP/Resources').then((mod) => mod.Resources));

const DynamicSecondStoryTellingGrid = dynamic(() =>
  import('@/components/LOHP/StoryTellingGrids').then((mod) => mod.SecondStoryTellingGrid),
);
const DynamicHeroFooter = dynamic(() => import('@/components/LOHP/Hero').then((mod) => mod.HeroFooter));

const LOHP = () => {
  const logoUrl = `${contributorCarbonWeb}/assets/images/Contributor_Redicon_100.png`;

  const socialLinks = socialShutterstockLinks.map(({ href }) => href);
  const { t } = useTranslation([NAMESPACE_COMMON]);

  const jsonLd = {
    '@context': SCHEMA_URL,
    '@type': SCHEMA_TYPES.ORGANIZATION,
    url: contributorCarbonWeb,
    logo: logoUrl,
    // sameAs - URL of a page on another website with additional information about your organization
    sameAs: socialLinks,
    description: t('contributor_helmet_description'),
    email: EMAIL,
  };

  return (
    <PageSection value={LOHPPageSections.lohp}>
      <Head>
        <link rel="canonical" href={contributorCarbonWeb} key="canonical" />
        <link rel="alternate" href={contributorCarbonWeb} hrefLang="x-default" />
        {languages.map(({ code }) => (
          <link key={code} rel="alternate" href={`${contributorCarbonWeb}/${code}`} hrefLang={code} />
        ))}
        {/* eslint-disable-next-line react/no-danger */}
        <script async type={SCRIPT_TYPES.JSON_LD} dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
      </Head>
      <Box
        data-testid="lohp-page-content"
        sx={({ spacing, breakpoints }: Theme) => ({
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
          marginLeft: '10%',
          marginRight: '10%',
          [breakpoints.down('sm')]: {
            paddingLeft: spacing(0),
            paddingRight: spacing(0),
            marginRight: '5%',
            marginLeft: '5%',
          },
          [breakpoints.between('sm', 'md')]: {
            paddingLeft: spacing(0),
            paddingRight: spacing(0),
            marginRight: '3%',
            marginLeft: '3%',
          },
        })}
      >
        <HeroHeader />
        <SellingPoints />
        <DynamicFirstStoryTellingGrid />
        <DynamicResources />
        <DynamicSecondStoryTellingGrid />
      </Box>

      <DynamicHeroFooter />
    </PageSection>
  );
};

export const getServerSideProps = async ({ req, res, locale }: Args) => {
  // Allow unauthenticated users to access LOHP
  const hasToken = await withAuth(req as Request, res as Response<any, Record<string, any>>, {
    allowUnauthenticated: true,
  });

  if (hasToken) {
    try {
      const user = await new CurrentUser(req).getCurrentUser();

      if (user) {
        return { redirect: { permanent: false, destination: '/dashboard' } };
      }
    } catch (error) {
      return handleServerSidePropsError({ req, error });
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [NAMESPACE_COMMON, NAMESPACE_LOHP])),
      pageTitle: 'contributor_lohp_meta_title_v2',
      displayBanner: false,
      shouldIndex: true,
    },
  };
};

LOHP.layout = {
  type: LOHPLayout,
};

export default LOHP;
