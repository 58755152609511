import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { LanguageSelect } from '@/components/LanguageSelect';
import { SiteHeaderLogo } from '@/components/SiteHeader/LoggedOut/SiteHeaderLogo';
import { Button } from '@/components/WithTracking/Button';
import { Link } from '@/components/WithTracking/Link';
import { CommonPageSections, NavTrackActions } from '@/constants/analytics';
import { LINK_TYPE, NON_C2N_ROUTES } from '@/constants/global';
import { useFormatLink } from '@/hooks/useFormatLink';
import { NAMESPACE_LOHP } from '@/translations/namespaces';

import styles from './siteHeader.module.css';

export const LOHPSiteHeader = ({ layoutStyles }: { layoutStyles: object }) => {
  const { locale } = useRouter();
  const { t } = useTranslation(NAMESPACE_LOHP);
  const { palette } = useTheme();

  const contributorBlogUrl = useFormatLink({
    linkType: LINK_TYPE.CONTRIBUTOR_BLOG,
  });

  const supportUrl = useFormatLink({
    href: '/s/',
    linkType: LINK_TYPE.CONTRIBUTOR_SUPPORT_CENTER,
    queries: { language: locale },
  });
  const shutterstockUrl = useFormatLink({
    href: '',
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
  });

  return (
    <PageSection value={CommonPageSections.header}>
      <AppBar
        data-testid="LOHP-Header-appbar"
        position="relative"
        color="transparent"
        elevation={0}
        className={styles.appBar}
      >
        <Container data-testid="LOHP-Header-appbar-container" disableGutters sx={layoutStyles}>
          <Box className={styles.headerGrid}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link href={NON_C2N_ROUTES.LOGIN} trackAction={NavTrackActions.loginLink}>
                <SiteHeaderLogo light size="small" />
              </Link>
              <>
                <Link
                  underline="none"
                  href={contributorBlogUrl}
                  target="_blank"
                  rel="noopener"
                  trackAction={NavTrackActions.contributorBlogLink}
                  className={`${styles.contributorHeaderLink} ${styles.link}`}
                >
                  {t('contributor_header_blog')}
                </Link>
                <Link
                  underline="none"
                  href={supportUrl}
                  target="_blank"
                  rel="noopener"
                  trackAction={NavTrackActions.contributorSupportCenter}
                  className={`${styles.contributorHeaderLink} ${styles.link}`}
                >
                  {t('contributor_header_support_center')}
                </Link>
              </>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <Link
                underline="none"
                href={shutterstockUrl}
                target="_blank"
                rel="noopener"
                trackAction={NavTrackActions.shutterstockHomeLink}
                className={`${styles.buyContentLink} ${styles.link}`}
              >
                {t('contributor_header_buy_content')}
              </Link>
              <Box className={styles.languageSelect}>
                <LanguageSelect sx={{ marginTop: '0px', border: '0px' }} color={palette.text.inverse} />
              </Box>
              <Button
                variant="secondary"
                sx={{
                  border: 1,
                  padding: ({ spacing }) => spacing(0, 2, 0, 2),
                  color: palette.text.inverse,
                  '&:hover': {
                    backgroundColor: palette.secondary.main,
                    borderColor: palette.secondary.main,
                  },
                }}
                href={NON_C2N_ROUTES.LOGIN}
                trackAction={NavTrackActions.loginLink}
              >
                {t('contributor_header_log_in')}
              </Button>
              <Button
                variant="primary"
                href={NON_C2N_ROUTES.SIGN_UP}
                trackAction={NavTrackActions.signUpLink}
                className={styles.signUpButton}
              >
                {t('sign_up_sentence_case')}
              </Button>
            </Box>
          </Box>
        </Container>
      </AppBar>
    </PageSection>
  );
};
